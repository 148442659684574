import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import FeaturePost from '../../components/feature-post/feature-post';
import PromotionImage from '../../images/ad.png';
import {
  SidebarWrapper,
  SidebarWidget,
  WidgetTitle,
  InstagramWrapper,
  InstagramPhoto,
} from './style';

type SidebarProps = {};

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { frontmatter: { isGroupPage: { eq: false } } }
        sort: { fields: [frontmatter___createdDate], order: DESC }
        limit: 5
      ) {
        edges {
          node {
            excerpt(pruneLength: 300)
            frontmatter {
              createdDate(formatString: "DD [<span>] MMM [</span>]")
              title
              seoDescription
              tags
              slug
              image {
                childImageSharp {
                  fluid(maxWidth: 90, maxHeight: 90, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  // Query for tags section
  // group(field: frontmatter___tags) {
  //   totalCount
  //   fieldValue
  // }

  // TODO: Enable instagram support later. Add below query to above.
  // allInstaNode(limit: 4) {
  //   edges {
  //     node {
  //       id
  //       likes
  //       comments
  //       localFile {
  //         childImageSharp {
  //           fluid(maxWidth: 100, maxHeight: 100, quality: 100) {
  //             ...GatsbyImageSharpFluid_noBase64
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  const Posts = Data.allMdx.edges;
  const InstagramPhotos = (Data.allInstaNode || []).edges;

  return (
    <SidebarWrapper>
      {/* <SidebarWidget>Insert Google Adsense Code Here......</SidebarWidget> */}

      <SidebarWidget>
        <WidgetTitle>Latest Post</WidgetTitle>
        {Posts.map(({ node }: any) => {
          const title = node.frontmatter.title || node.frontmatter.slug;
          // Random Placeholder Color
          const placeholderColors = [
            '#55efc4',
            '#81ecec',
            '#74b9ff',
            '#a29bfe',
            '#ffeaa7',
            '#fab1a0',
            '#e17055',
            '#0984e3',
            '#badc58',
            '#c7ecee',
          ];
          const setColor = placeholderColors[Math.floor(Math.random() * placeholderColors.length)];

          return (
            <FeaturePost
              key={node.frontmatter.slug}
              title={title}
              image={
                node.frontmatter.cover == null ? null : node.frontmatter.cover.childImageSharp.fluid
              }
              url={node.frontmatter.slug}
              tags={node.frontmatter.tags}
              placeholderBG={setColor}
            />
          );
        })}
      </SidebarWidget>

      {/* <SidebarWidget>
        <WidgetTitle>Instagram</WidgetTitle>
        {InstagramPhotos ? (
          <InstagramWrapper>
            {InstagramPhotos.map(({ node }: any) => {
              // Random Placeholder Color
              const placeholderColors = [
                '#55efc4',
                '#81ecec',
                '#74b9ff',
                '#a29bfe',
                '#ffeaa7',
                '#fab1a0',
                '#e17055',
                '#0984e3',
                '#badc58',
                '#c7ecee',
              ];
              const setColor =
                placeholderColors[Math.floor(Math.random() * placeholderColors.length)];

              return (
                <InstagramPhoto key={node.id}>
                  <a href={`https://www.instagram.com/p/${node.id}`} target="_blank">
                    <Img
                      fluid={node.localFile.childImageSharp.fluid}
                      alt="Instagram Photo"
                      backgroundColor={setColor}
                    />
                  </a>
                </InstagramPhoto>
              );
            })}
          </InstagramWrapper>
        ) : (
          ''
        )}
      </SidebarWidget> */}
    </SidebarWrapper>
  );
};

export default Sidebar;
