import * as React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import {
  PostDetailsWrapper,
  PostTitle,
  PostDate,
  PostPreview,
  PostDescriptionWrapper,
  PostDescription,
  PostTags,
  WishListImage,
  PoemTitle,
  PoemContent,
  PostItem,
  PoemItem,
  WishItem,
} from './post-details.style';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { generateTagPagePath } from '../../utils';
import FlowerImage from '../../images/flower.png';

type PostDetailsProps = {
  title: string;
  date?: string;
  preview?: any;
  description?: any;
  tags?: [];
  className?: string;
  imagePosition?: 'left' | 'top';
  body: any;
  listContent: { wishes?: any[]; poems?: any[]; giftIdeas?: any[]; eCards?: any[] };
};

const PostDetails: React.FunctionComponent<PostDetailsProps> = ({
  title,
  date,
  preview,
  description,
  body,
  listContent,
  tags,
  className,
  imagePosition,
  ...props
}) => {
  const wishes = listContent.wishes;
  const poems = listContent.poems;
  const giftIdeas = listContent.giftIdeas;
  const eCards = listContent.eCards;
  const addClass: string[] = ['post_details'];

  if (imagePosition == 'left') {
    addClass.push('image_left');
  }

  if (className) {
    addClass.push(className);
  }

  // Random Placeholder Color
  const placeholderColors = [
    '#55efc4',
    '#81ecec',
    '#74b9ff',
    '#a29bfe',
    '#ffeaa7',
    '#fab1a0',
    '#e17055',
    '#0984e3',
    '#badc58',
    '#c7ecee',
  ];
  const setColor = placeholderColors[Math.floor(Math.random() * placeholderColors.length)];

  return (
    <PostDetailsWrapper {...props} className={addClass.join(' ')}>
      {imagePosition == 'left' ? (
        <>
          {preview == null ? null : (
            <PostPreview className="post_preview">
              <Img fluid={preview} alt={title} backgroundColor={setColor} />
            </PostPreview>
          )}
        </>
      ) : (
        ''
      )}

      {imagePosition == 'top' ? (
        <>
          <PostTitle>{title}</PostTitle>
          <PostDate>{date}</PostDate>
        </>
      ) : (
        ''
      )}

      {imagePosition == 'top' ? (
        <>
          {preview == null ? null : (
            <PostPreview className="post_preview">
              <Img fluid={preview} alt={title} backgroundColor={setColor} />
            </PostPreview>
          )}
        </>
      ) : (
        ''
      )}
      <PostDescriptionWrapper className="post_des_wrapper">
        {imagePosition == 'left' ? (
          <>
            <PostTitle>{title}</PostTitle>
            <PostDate>{date}</PostDate>
          </>
        ) : (
          ''
        )}
        <PostDescription className="post_des">
          <MDXRenderer>{body}</MDXRenderer>
          {wishes &&
            wishes.map((wish, index) => (
              <WishItem
                key={index}
                style={{
                  padding: wish.image && '0',
                }}
              >
                {wish.text && !wish.image && (
                  <WishListImage src={FlowerImage} alt="wish-list-item-icon" />
                )}
                {wish.image && <Img fluid={wish.image.childImageSharp.fluid} loading="lazy" />}
                {wish.text}
              </WishItem>
            ))}
          {poems &&
            poems.map((poem, index) => (
              <PoemItem key={index}>
                {poem.title && <PoemTitle>{poem.title}</PoemTitle>}
                <PoemContent>{poem.body}</PoemContent>
              </PoemItem>
            ))}
          {giftIdeas &&
            giftIdeas.map((giftIdea, index) => (
              <PostItem key={index}>
                {giftIdea.title && <p>{giftIdea.title}</p>}
                {giftIdea.body}
              </PostItem>
            ))}
          {eCards &&
            eCards.map((eCard, index) => (
              <div key={index} className="e-card-image-container">
                <Img alt={eCard.altText} fluid={eCard.image.childImageSharp.fluid} />
                <a className="download-link" href={eCard.image.childImageSharp.fluid.src} download>
                  Download
                </a>
              </div>
            ))}
        </PostDescription>
        {tags == null ? null : (
          <PostTags>
            {tags.map((tag, index) => (
              <Link key={index} to={generateTagPagePath(tag)}>
                {`#${tag}`}
              </Link>
            ))}
          </PostTags>
        )}
      </PostDescriptionWrapper>
    </PostDetailsWrapper>
  );
};

PostDetails.defaultProps = {
  imagePosition: 'top',
};

export default PostDetails;
