import React from 'react';
import { graphql, Link } from 'gatsby';
import urljoin from 'url-join';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import Sidebar from '../containers/sidebar';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  RedditShareButton,
} from 'react-share';
import { IoLogoFacebook, IoLogoTwitter, IoLogoPinterest, IoLogoReddit } from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogDetailsContent,
} from './templates.style';
import { generateCategoryPagePath, generateGroupPagePath, generateTagPagePath } from '../utils';
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs';

const BlogPostTemplate = (props: any) => {
  const post = props.data.pageContent;
  let { nodes } = props.data.relatedContent;
  const { title, slug, group, category } = post.frontmatter;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);
  const breadcrumbs = [
    {
      label: group,
      link: `/${generateGroupPagePath(group)}`,
    },
    {
      label: category,
      link: `/${generateCategoryPagePath(group, category)}`,
    },
    {
      label: title
    }
  ];

  nodes = nodes.filter((node) => node.frontmatter.slug !== post.frontmatter.slug);

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.seoDescription || post.excerpt}
      />
      <Breadcrumbs data={breadcrumbs} />
      <BlogPostDetailsWrapper>
        <BlogDetailsContent>
          <PostDetails
            title={post.frontmatter.title}
            date={post.frontmatter.createDate}
            body={post.body}
            listContent={post.exports.content}
            className={post.frontmatter.contentType}
            preview={
              post.frontmatter.image == null || post.frontmatter.contentType === 'e-cards'
                ? null
                : post.frontmatter.image.childImageSharp.fluid
            }
          />

          <BlogPostFooter>
            {post.frontmatter.tags == null ? null : (
              <PostTags className="post_tags">
                {post.frontmatter.tags
                  .split(', ')
                  .filter((tag) => tag !== 'featured')
                  .map((tag: string, index: number) => (
                    <Link key={index} to={generateTagPagePath(tag)}>
                      {`#${tag}`}
                    </Link>
                  ))}
              </PostTags>
            )}
            <PostShare>
              <span>Share This:</span>
              <FacebookShareButton url={shareUrl} quote={post.excerpt}>
                <IoLogoFacebook />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <IoLogoTwitter />
              </TwitterShareButton>
              {post.frontmatter.image && (
                <PinterestShareButton
                  url={shareUrl}
                  media={urljoin(siteUrl, post.frontmatter.image.publicURL)}
                >
                  <IoLogoPinterest />
                </PinterestShareButton>
              )}
              <RedditShareButton url={shareUrl} title={`${post.frontmatter.title}`}>
                <IoLogoReddit />
              </RedditShareButton>
            </PostShare>
          </BlogPostFooter>
        </BlogDetailsContent>
        <Sidebar />
      </BlogPostDetailsWrapper>
      {nodes.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {nodes.map((node) => {
              // Random Placeholder Color
              const placeholderColors = [
                '#55efc4',
                '#81ecec',
                '#74b9ff',
                '#a29bfe',
                '#ffeaa7',
                '#fab1a0',
                '#e17055',
                '#0984e3',
                '#badc58',
                '#c7ecee',
              ];
              const setColor =
                placeholderColors[Math.floor(Math.random() * placeholderColors.length)];
              return (
                <RelatedPostItem key={node.frontmatter.slug}>
                  <PostCard
                    title={node.frontmatter.title || node.frontmatter.slug}
                    url={node.frontmatter.slug}
                    image={
                      node.frontmatter.image == null
                        ? null
                        : node.frontmatter.image.childImageSharp.fluid
                    }
                    tags={node.frontmatter.tags.split(', ').filter((tag) => tag !== 'featured')}
                    placeholderBG={setColor}
                  />
                </RelatedPostItem>
              );
            })}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($group: String!, $category: String!, $tags: String!, $slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    pageContent: mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        seoDescription
        createdDate(formatString: "DD MMM, YYYY")
        tags
        slug
        contentType
        group
        category
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1170, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      body
      exports {
        content {
          wishes {
            text
            image {
              childImageSharp {
                fluid(maxWidth: 750, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            altText
          }
          # poems {
          #  title
          #  body
          #}
          #giftIdeas {
          #  title
          #  body
          #}
          eCards {
            altText
            image {
              childImageSharp {
                fluid(maxWidth: 750, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    relatedContent: allMdx(
      # limit: 3
      sort: { fields: [frontmatter___createdDate], order: DESC }
      filter: {
        frontmatter: { group: { eq: $group }, category: { eq: $category }, tags: { regex: $tags } }
      }
    ) {
      nodes {
        id
        frontmatter {
          title
          tags
          slug
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 370, maxHeight: 220, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
